// my-iptv-site\src\components\FeaturesSection.js
import React from 'react';
import '../App.css';

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <div className="feature">
        <span className="feature-icon" role="img" aria-label="Globe">🌍</span>
        <h3>Chaînes de 115 pays dans le monde</h3>
        <p>Vous pouvez regarder des chaînes de télévision du monde entier (France /Pays-Bas / Belgique / Allemagne / Portugal-Uni / Espagne / Royaume-Uni  / Pologne / Italie / Ex-Yougoslavie / Hindi / Arabe / Turquie...)</p>
      </div>
      <div className="feature">
        <span className="feature-icon" role="img" aria-label="Sac d'argent">💰</span>
        <h3>Garantie de remboursement de  <br></br>7 jours</h3>
        <p>Dans les 7 jours suivant votre achat, vous avez la possibilité d'annuler notre abonnement IPTV si vous n'êtes pas satisfait. Vous recevrez un remboursement complet de notre part.</p>
      </div>
      <div className="feature">
        <span className="feature-icon" role="img" aria-label="Visage souriant">😊</span>
        <h3>Haute qualité HD/FHD/4K/8K</h3>
        <p>Nous offrons toutes les qualités d'image pour profiter de notre service IPTV partout, quelle que soit la vitesse de votre réseau, sur : Mobile / TV / Box Android / PC...</p>
      </div>
    </section>
  );
};

export default FeaturesSection;
