
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Custom404 = () => {
  return (
    <>
      <Helmet>
        <title>Page Non Trouvée - IPTV France 4K</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-6xl font-bold mb-4">404</h1>
          <h2 className="text-2xl mb-8">Page Non Trouvée</h2>
          
          <div className="space-y-4">
            <p>La page que vous recherchez n'existe pas ou a été déplacée.</p>
            
            <div className="space-y-2">
              <h3 className="text-xl">Pages Populaires</h3>
              <ul className="space-y-2">
                <li><Link to="/" className="text-blue-400 hover:underline">Accueil</Link></li>
                <li><Link to="/blogs" className="text-blue-400 hover:underline">Blog</Link></li>
                <li><Link to="/#pricing" className="text-blue-400 hover:underline">Abonnements</Link></li>
              </ul>
            </div>
            
            <Link 
              to="/"
              className="inline-block bg-blue-600 px-6 py-2 rounded-full mt-4 hover:bg-blue-700"
            >
              Retour à l'accueil
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Custom404;