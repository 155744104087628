//C:\Users\Piman\ipt\my-iptv-site\src\components\Footer.js
import React from 'react';
import { FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact</h3>
            <p className="flex items-center mb-2">
              <FaMapMarkerAlt className="mr-2" />
              170 Rue Léon Gambetta, 59000 Lille
            </p>
            <p className="flex items-center">
              <FaWhatsapp className="mr-2" />
              <span>WhatsApp : </span>
              <a href="https://wa.me/33773309311" target="_blank" rel="noopener noreferrer">
                +33 7 73 30 93 11
              </a>
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Liens utiles</h3>
            <ul className="space-y-2">
              <li>Accueil</li>
              <li>Services</li>
              <li>Tarifs</li>
              <li>Contact</li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Mentions légales</h3>
            <ul className="space-y-2">
              <li>Conditions générales de vente</li>
              <li>Politique de confidentialité</li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 text-center">
          <p>&copy; {new Date().getFullYear()} IPTVFRANCE4K. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;