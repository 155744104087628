// C:\Users\Piman\ipt\my-iptv-site\src\components\BlogDetails.js

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './BlogDetails.css';

import blog1 from '../blogsData/blog1';
import blog2 from '../blogsData/blog2';
import blog3 from '../blogsData/blog3';
import blog4 from '../blogsData/blog4';
import blog5 from '../blogsData/blog5';
import blog6 from '../blogsData/blog6';
import blog7 from '../blogsData/blog7';
import blog8 from '../blogsData/blog8';
import blog9 from '../blogsData/blog9';
import blog10 from '../blogsData/blog10';
import blog11 from '../blogsData/blog11';
import blog12 from '../blogsData/blog12';
const blogs = [blog1, blog2, blog3, blog4, blog5,blog6,blog7,blog8,blog9,blog10,blog11,blog12];
const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const blog = blogs.find((b) => b.id === parseInt(id, 10));

  useEffect(() => {
    // Si le blog n'existe pas, rediriger vers la page 404
    if (!blog) {
      navigate('/404', { replace: true });
      return;
    }

    const handlePricingClick = (e) => {
      e.preventDefault();
      navigate('/', { state: { scrollTo: 'pricing' } });
    };

    const button = document.getElementById('pricingButton');
    if (button) {
      button.addEventListener('click', handlePricingClick);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handlePricingClick);
      }
    };
  }, [navigate, blog]);

  if (!blog) return null; // Retourner null car la redirection est gérée dans useEffect

  return (
    <>
      <Helmet>
        <title>{blog.metaTitle}</title>
        <meta name="description" content={blog.metaDescription} />
        <meta property="og:title" content={blog.metaTitle} />
        <meta property="og:description" content={blog.metaDescription} />
        <meta property="og:image" content={`https://iptvfrance4k.fr${blog.imageUrl}`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://iptvfrance4k.fr/blogs/${id}`} />
        <script type="application/ld+json">
          {JSON.stringify(blog.schema)}
        </script>
      </Helmet>

      <article className="blog-details bg-black text-white px-8 py-24">
        <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
        <div className="meta mb-6">
          <p className="text-gray-400 text-sm">{blog.date}</p>
          <p className="text-gray-400 text-sm">Par {blog.author.name}</p>
        </div>
        
        {blog.imageUrl && (
          <img 
            src={blog.imageUrl} 
            alt={blog.imageAlt || blog.title} 
            className="w-full h-68 object-cover rounded-lg mb-8" 
          />
        )}
        
        <div 
          className="blog-content prose prose-invert max-w-none"
          dangerouslySetInnerHTML={{ __html: blog.content }} 
        />
        
        <footer className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-wrap gap-2">
            {blog.tags.map(tag => (
              <span key={tag} className="bg-gray-800 px-3 py-1 rounded-full text-sm">
                {tag}
              </span>
            ))}
          </div>
        </footer>
      </article>
    </>
  );
};

export default BlogDetails;