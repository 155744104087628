// C:\Users\Piman\ipt\my-iptv-site\src\components\Pricing.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const plans = [
  { 
    duration: '3_months', 
    price: '3_months_price', 
    isPopular: false,
    stripeLink: 'https://buy.stripe.com/fZeeYbeor8m28807st'
  },
  { 
    duration: '6_months', 
    price: '6_months_price', 
    isPopular: false,
    stripeLink: 'https://buy.stripe.com/28o2bp3JNdGm1JC146'
  },
  { 
    duration: '12_months', 
    price: '12_months_price', 
    isPopular: true,
    stripeLink: 'https://buy.stripe.com/28oeYb3JNbye880fZ1'
  }
];

const Pricing = () => {
  const { t } = useTranslation();

  const handlePurchase = (stripeLink) => {
    // Ouvre le lien dans un nouvel onglet
    window.open(stripeLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <section id="pricing" className="bg-black text-white py-20 px-8 text-center">
      <h2 className="text-5xl font-bold mb-12">{t('pricing.title')}</h2>
      <div className="flex flex-wrap justify-center gap-12">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-box text-white shadow-lg rounded-lg p-8 ${
              plan.isPopular ? 'border border-yellow-400' : ''
            }`}
          >
            {plan.isPopular && (
              <div className="bg-yellow-400 text-black text-xs font-bold rounded-full px-3 py-1 mb-4">
                Popular
              </div>
            )}
            <h3 className="text-2xl font-semibold mb-2">{t(`pricing.${plan.duration}`)}</h3>
            <p className="text-5xl font-bold mb-2">{t(`pricing.${plan.price}`)}</p>
            <p className="text-lg text-gray-400 mb-6">{t('pricing.promo')}</p>

            <ul className="features-list text-left mb-8 text-gray-300 text-lg">
              {(Array.isArray(t('pricing.features', { returnObjects: true }))
                ? t('pricing.features', { returnObjects: true })
                : []
              ).map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>

            <button 
              onClick={() => handlePurchase(plan.stripeLink)}
              className="bg-yellow-400 text-black font-bold py-3 px-6 rounded-full w-full mb-6 hover:bg-yellow-500 transition-colors duration-300"
            >
              {t('pricing.button_text')}
            </button>
            <p className="text-sm text-gray-400">{t('pricing.availability')}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pricing;