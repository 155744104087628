// src/components/SEO/SEOWrapper.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEOWrapper = ({ children, 
  title = "IPTV France 4K | Service Premium de Streaming TV",
  description = "Service premium IPTV français avec +18000 chaînes en HD/4K. VOD, séries et sport en direct. Essai gratuit et support 24/7.",
  canonical,
  lang = "fr" }) => {
  
  const baseUrl = "https://iptvfrance4k.fr";
  
  return (
    <>
      <Helmet>
        {/* Encodage et langue */}
        <html lang={lang} />
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Meta tags SEO de base */}
        <title>{`${title} - IPTV France 4K`}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`${baseUrl}${canonical || ''}`} />
        
        {/* Meta tags Open Graph */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${baseUrl}${canonical || ''}`} />
        <meta property="og:image" content={`${baseUrl}/og-image.jpg`} />
        <meta property="og:site_name" content="IPTV France 4K" />
        
        {/* Meta tags Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${baseUrl}/twitter-image.jpg`} />
        
        {/* Hreflang tags */}
        <link rel="alternate" hrefLang="fr" href={`${baseUrl}${canonical || ''}`} />
        <link rel="alternate" hrefLang="x-default" href={`${baseUrl}${canonical || ''}`} />
        
        {/* Autres meta tags importants */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta name="theme-color" content="#0072bc" />
      </Helmet>
      {children}
    </>
  );
};

export default SEOWrapper;