//app
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import MovieCarousel from './components/MovieCarousel';
import FeaturesSection from './components/FeaturesSection';
import Pricing from './components/Pricing';
import BlogList from './components/BlogList';
import BlogDetails from './components/BlogDetails';
import SEOWrapper from './components/SEO/SEOWrapper';
import Analytics from './components/Analytics';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';
import Custom404 from './components/Custom404';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <ScrollToTop />
        <Analytics />
        
        {/* SEO Base Configuration */}
        <Helmet>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
          <link rel="canonical" href="https://iptvfrance4k.fr" />
          
          {/* Preconnect to important origins */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
          
          {/* Default SEO */}
          <title>IPTV France 4K - Service Premium de Streaming TV</title>
          <meta name="description" content="Service IPTV premium français avec +18000 chaînes HD/4K, VOD illimitée et support 24/7. Qualité et stabilité garanties." />
          
          {/* Open Graph */}
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="IPTV France 4K" />
          <meta property="og:locale" content="fr_FR" />
          
          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          
          {/* Favicons and other icons */}
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" href="/logo192.png" />
          
          {/* Manifest */}
          <link rel="manifest" href="/manifest.json" />
        </Helmet>

        <div className="App">
          <Header />
          
          <main id="main-content">
            <Routes>
              <Route
                path="/"
                element={
                  <SEOWrapper
                    title="IPTV France 4K - Le Meilleur du Streaming TV"
                    description="Découvrez notre service IPTV premium avec plus de 18000 chaînes en qualité HD et 4K. VOD illimitée et support client 24/7."
                    canonical="/"
                  >
                    <HeroSection id="hero" />
                    <MovieCarousel />
                    <FeaturesSection id="services" />
                    <Pricing id="pricing" />
                  </SEOWrapper>
                }
              />
              
              <Route 
                path="/blogs" 
                element={
                  <SEOWrapper
                    title="Blog IPTV - Actualités et Guides"
                    description="Découvrez nos articles et guides sur l'IPTV, le streaming et la télévision en ligne. Conseils d'experts et tutoriels."
                    canonical="/blogs"
                  >
                    <BlogList />
                  </SEOWrapper>
                }
              />
              
              <Route 
                path="/blogs/:id" 
                element={<BlogDetails />}
              />
              
              <Route 
                path="*" 
                element={
                  <SEOWrapper
                    title="Page Non Trouvée - IPTV France 4K"
                    description="La page que vous recherchez n'existe pas ou a été déplacée."
                    canonical="/404"
                    noIndex={true}
                  >
                    <Custom404 />
                  </SEOWrapper>
                }
              />
            </Routes>
          </main>
          
          <Footer />
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;