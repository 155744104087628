//C:\Users\Piman\ipt\my-iptv-site\src\components\Header.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePricingClick = (e) => {
    e.preventDefault();
    navigate('/');
    setTimeout(() => {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <header className="bg-black text-white p-4 flex justify-between items-center">
      {/* Affichage du logo avec styles ajoutés */}
      <Link to="/">
        <img
          src="/logo/iptvfrance1.png"
          alt="Logo IPTV France"
          className="h-20 w-auto object-contain transition-transform duration-300 hover:scale-105"
          // "h-20" pour augmenter la hauteur, "object-contain" pour garder le ratio, "hover:scale-105" pour effet zoom au survol
        />
      </Link>
      <nav className="space-x-4 flex items-center">
        <Link to="/" className="hover:text-gray-300">Accueil</Link>
        <button onClick={handlePricingClick} className="hover:text-gray-300">
          {t('header.pricing')}
        </button>
        <Link to="/blogs" className="hover:text-gray-300">Blog</Link>
      </nav>
    </header>
  );
};

export default Header;
