//C:\Users\Piman\ipt\my-iptv-site\src\components\BlogList.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SEOWrapper from './SEO/SEOWrapper';

// Import des données des blogs
import blog1 from '../blogsData/blog1';
import blog2 from '../blogsData/blog2';
import blog3 from '../blogsData/blog3';
import blog4 from '../blogsData/blog4';
import blog5 from '../blogsData/blog5';
import blog6 from '../blogsData/blog6';
import blog7 from '../blogsData/blog7';
import blog8 from '../blogsData/blog8';
import blog9 from '../blogsData/blog9';
import blog10 from '../blogsData/blog10';
import blog11 from '../blogsData/blog11';
import blog12 from '../blogsData/blog12';

const blogs = [blog1, blog2, blog3, blog4, blog5, blog6, blog7, blog8, blog9, blog10, blog11, blog12];

const BlogList = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrer les blogs en fonction du terme de recherche
  const filteredBlogs = blogs.filter(blog => 
    blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    blog.metaDescription?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Formatage de la date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  return (
    <SEOWrapper
      title="Blog IPTV - Guides et Actualités"
      description="Découvrez nos articles et guides sur l'IPTV, streaming et télévision en ligne."
      canonical="/blogs"
    >
      <section className="blog-section bg-black text-white px-8 py-24">
        {/* En-tête de la section blog */}
        <div className="text-center mb-12">
          <h1 className="text-6xl md:text-8xl font-extrabold mb-8">
            {t('blog.title')}
          </h1>
          
          {/* Barre de recherche */}
          <div className="max-w-xl mx-auto mb-16">
            <input
              type="search"
              placeholder="Rechercher un article..."
              className="w-full px-6 py-3 bg-gray-900 text-white rounded-full border border-gray-700 focus:outline-none focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Grille des articles */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {filteredBlogs.map((blog) => (
            <article 
              key={blog.id} 
              className="bg-gray-900 rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300"
              itemScope 
              itemType="http://schema.org/BlogPosting"
            >
              <Link to={`/blogs/${blog.id}`} className="block">
                {blog.imageUrl && (
                  <div className="relative pb-[60%] overflow-hidden">
                    <img 
                      src={blog.imageUrl} 
                      alt={blog.imageAlt || blog.title}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      loading="lazy"
                      itemProp="image"
                    />
                  </div>
                )}
                
                <div className="p-6 space-y-4 text-center md:text-left">
                  {/* Meta informations */}
                  <div className="flex flex-wrap gap-2 justify-center md:justify-start mb-3">
                    <time 
                      className="text-gray-400 text-sm"
                      dateTime={blog.date}
                      itemProp="datePublished"
                    >
                      {formatDate(blog.date)}
                    </time>
                    {blog.categories?.map((category) => (
                      <span 
                        key={category}
                        className="text-xs bg-blue-600 text-white px-2 py-1 rounded-full"
                      >
                        {category}
                      </span>
                    ))}
                  </div>

                  {/* Titre et extrait */}
                  <h2 
                    className="text-2xl font-bold hover:text-blue-400 transition-colors"
                    itemProp="headline"
                  >
                    {blog.title}
                  </h2>
                  
                  <div 
                    className="text-gray-300 line-clamp-3"
                    itemProp="description"
                  >
                    {blog.metaDescription || blog.content.substring(0, 150) + '...'}
                  </div>

                  {/* Bouton Lire Plus */}
                  <button className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-500 transition duration-300 mt-4">
                    {t('blog.button')}
                  </button>
                </div>
              </Link>
            </article>
          ))}
        </div>

        {/* Message si aucun résultat */}
        {filteredBlogs.length === 0 && (
          <div className="text-center py-16">
            <p className="text-gray-400 text-xl">
              Aucun article ne correspond à votre recherche.
            </p>
          </div>
        )}
      </section>
    </SEOWrapper>
  );
};

export default BlogList;