// C:\Users\Piman\ipt\my-iptv-site\src\components\MovieCarousel.js
import React, { useEffect, useRef } from 'react';

const MovieCarousel = () => {
  const moviePosters = [
    '/VOD/086520.jpg',
    '/VOD/ibiza.jpg',
    '/VOD/you.jpg',
    '/VOD/faraway.jpeg',
    '/VOD/furiosa.jpg',
  
    '/VOD/wonder.jpg',
    '/VOD/barbes.jpg'
  ];

  const icons = [
    '/logo/beinsport.png',
    '/logo/skynews.png',
    '/logo/prime.png',
    '/logo/hulu.jpg',
    '/logo/discov.png',
    '/logo/netflix.png',
    '/logo/disnep.png',
    '/logo/premier.jpg',
    '/logo/nba.png',
    '/logo/champ.jpg',
    '/logo/dutch.png'
  ];

  const scrollRef = useRef(null);
  const iconScrollRef = useRef(null);
  const animationFrameRef = useRef(null);
  

  useEffect(() => {
    let scrollPosition = 0;
    let iconScrollPosition = 0;

    const animate = () => {
      if (scrollRef.current && iconScrollRef.current) {
        // Animation des films
        scrollPosition += 1;
        scrollRef.current.style.transform = `translateX(-${scrollPosition}px)`;
        if (scrollPosition >= scrollRef.current.firstChild.offsetWidth) {
          scrollPosition = 0;
        }

        // Animation des logos
        iconScrollPosition += 0.5;
        iconScrollRef.current.style.transform = `translateX(-${iconScrollPosition}px)`;
        if (iconScrollPosition >= iconScrollRef.current.firstChild.offsetWidth) {
          iconScrollPosition = 0;
        }

        animationFrameRef.current = requestAnimationFrame(animate);
      }
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <section className="movie-carousel bg-black text-white py-8">
      {/* Films Carousel */}
      <div className="relative overflow-hidden">
        <div 
          ref={scrollRef} 
          className="flex"
          style={{
            width: 'max-content',
            transition: 'transform 0.1s linear'
          }}
        >
          {/* Original posters */}
          {moviePosters.map((poster, index) => (
            <img
              key={index}
              src={poster}
              alt="Movie Poster"
              className="w-60 h-96 object-cover rounded-lg mx-4 transform hover:scale-110 transition duration-300"
            />
          ))}
          {/* Duplicated posters for infinite scroll */}
          {moviePosters.map((poster, index) => (
            <img
              key={`dup-${index}`}
              src={poster}
              alt="Movie Poster"
              className="w-60 h-96 object-cover rounded-lg mx-4 transform hover:scale-110 transition duration-300"
            />
          ))}
        </div>
      </div>

      {/* Logos Carousel */}
      <div className="relative overflow-hidden mt-8">
        <div 
          ref={iconScrollRef} 
          className="flex"
          style={{
            width: 'max-content',
            transition: 'transform 0.1s linear'
          }}
        >
          {/* Original icons */}
          {icons.map((icon, index) => (
            <img
              key={index}
              src={icon}
              alt="Platform Icon"
              className="w-20 h-10 mx-4 transform hover:scale-105 transition duration-300"
            />
          ))}
          {/* Duplicated icons for infinite scroll */}
          {icons.map((icon, index) => (
            <img
              key={`dup-${index}`}
              src={icon}
              alt="Platform Icon"
              className="w-20 h-10 mx-4 transform hover:scale-105 transition duration-300"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MovieCarousel;