
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Analytics = () => {
  const location = useLocation();
  const TRACKING_ID = 'G-XXXXXXXXXX'; // Votre ID Google Analytics

  useEffect(() => {
    // Chargement de Google Analytics
    const loadGoogleAnalytics = () => {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', TRACKING_ID);
    };

    loadGoogleAnalytics();

    // Tracker les changements de page
    const trackPageView = () => {
      if (window.gtag) {
        window.gtag('config', TRACKING_ID, {
          page_path: location.pathname + location.search
        });
      }
    };

    trackPageView();
  }, [location]);

  return null;
};

export default Analytics;