//C:\Users\Piman\ipt\my-iptv-site\src\components\HeroSection.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import SEOWrapper from './SEO/SEOWrapper';

const HeroSection = () => {
  const { t } = useTranslation();

  const handleWhatsAppClick = () => {
    const whatsappNumber = "33773309311";
    window.open(`https://wa.me/${whatsappNumber}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <SEOWrapper
      title={t('hero.title')}
      description={t('hero.description')}
      canonical="/"
    >
      <section className="hero-section">
        <div className="hero-text">
          {/* Utilisation du titre exact du fichier de traduction */}
          <h1>
            {t('hero.title')}
          </h1>
          
          {/* Utilisation de la description exacte du fichier de traduction */}
          <p>
            {t('hero.description')}
          </p>

          {/* Bouton avec le texte exact du fichier de traduction */}
          <button 
            className="hero-button"
            onClick={handleWhatsAppClick}
            aria-label={t('hero.button')}
          >
            {t('hero.button')}
          </button>
        </div>

        {/* Image hero avec alt text approprié */}
        <img 
          src="/Avatar1.jpg"
          alt="Interface IPTV France 4K"
          className="hero-image"
          loading="eager"
          width="600"
          height="400"
        />
      </section>

      {/* Intégration des services directement dans la section hero si nécessaire */}
      <div className="hero-features hide-on-mobile">
        <div className="feature">
          <h3>{t('services.service1_title')}</h3>
          <p>{t('services.service1_description')}</p>
        </div>
        <div className="feature">
          <h3>{t('services.service2_title')}</h3>
          <p>{t('services.service2_description')}</p>
        </div>
        <div className="feature">
          <h3>{t('services.service3_title')}</h3>
          <p>{t('services.service3_description')}</p>
        </div>
      </div>
    </SEOWrapper>
  );
};

export default HeroSection;